<template>
  <section id="chat-bar">
    <h2>Можете вступить в ЧАТ и обсуждать вопросы с Коллегами</h2>
    
    <div class="box-center bg-blue-600">
      <ul>
        <li><a href="https://chat.whatsapp.com/7XccA5zKej44JPuGWaB4nJ">Чат меховщиков в Ватсапе</a></li>
        <li><a href="https://t.me/+FzW13Z-Brn4oAB94">Чат Меховщиков в Телеграмм</a></li>
        <p>Присоединяйтесь и Вы!</p>
      </ul>
    </div>
    
    <p>Впоследствии я всегда на Связи!</p>
  </section>
</template>


<script>
  export default {
    name: "ChatBr",
    props: {
      msg: String
    }
}
</script>

<style>

  /* !TODO style for clickable buttons for messangers */
  /* li {
    list-style-type: none;
    
  }
  li a {
    background: #000;
    text-decoration: none;
    padding: 7px 5px 7px 5px;
    margin-bottom: 45px;
  } */

  a {
    text-decoration: none;
    margin: 12px;

  }
  li {
    padding-top: 12px;
  }
</style>