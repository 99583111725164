<template>
  <!-- Videos -->

  <section id="vidoe-otgruzka" class="flex w100">
    <!-- video 1 -->
    <div class="flex-item">
      <video
      src="../assets/video/otgruzka.mp4"
      type="video/mp4"
      width="200" 
      height="400"
      controls="controls"
      loop
      class="clip w-full">
      </video>
    </div>

    <!-- video 2 -->
    <div class="flex-item">
      <video
      src="../assets/video/otgruz2.mp4"
      type="video/mp4"
      width="200" 
      height="400"
      controls="controls"
      class="clip w-full">
      </video>
    </div>
  </section>
  <!-- END Videos --> 
</template>

<script>

  export default {
    name: "ChatBr"
  }
</script>

<style>

</style>